import './App.css';
import logo from './logo.svg';

// Test
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {Object.keys(process.env).filter(x => x.startsWith('REACT_APP_')).map(x =>
          <p key={x}>
            {x}: {process.env[x]}
          </p>
        )}
      </header>
    </div>
  );
}

export default App;
